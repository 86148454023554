import React from "react"
import { graphql } from "gatsby"
import DynamicZone from "../components/organisms/dynamic-zone"
const Page = ({ data: { strapiMeetTheFilmakers: fm } }) => {
  return <DynamicZone components={fm.containers} />
}

export const query = graphql`
  query FilmMakers($locale: String) {
    strapiMeetTheFilmakers(locale: { eq: $locale }) {
      name
      slug
      containers {
        ...DynamicZoneFragment
      }
    }
  }
`

export default Page
